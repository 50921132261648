import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Page from "../Page";
import Dates from "../../components/Calendar/Dates";
import Utils from "../../utils/Utils";
import AppLink from "../../ui/links/AppLink";
import Image from "../../ui/Image/Image";

import "./Church.scss";

const Church = (props) => {
  const { lang, media } = props;
  const useFullWidth = !(
    media === Utils.media.MOBILE ||
    media === Utils.media.LANDSCAPE ||
    media === Utils.media.TABLET ||
    media === Utils.media.DESKTOP
  );

  const isSwedish = lang === "swedish";

  return (
    <Page title="Källa gamla kyrka | Mya idé">
      <section>
        <div className="church-page">
          <div className="image-wrapper">
            {/* <Image
              alt="Fossiler i sten"
              src="/images/stenen_berattar_1390x500.jpg"
              ratio={1390 / 500}
            /> */}
            <Image
              alt="Ölandsk kustlinje"
              src="/images/church2_1917x742.webp"
              ratio={1917 / 742}
            />
          </div>

          <div
            className={cx("calendar", {
              full: useFullWidth,
            })}
          >
            <Dates useFullWidth={useFullWidth} lang={lang} />

            <div className="calendar-info church">
              <div className="calendar-type">
                <div className="calendar-type-header">
                  <div className="bg" />
                  <h3>Kalkstenstemplet i Källa</h3>
                </div>

                <div className="mt-20">
                  <p>
                    Vi lyssnar till stenväggars 1000-åriga historia om pirater
                    och pilgrimer, om ny kristenhet och helig källa.
                  </p>
                  Ölandsguide Ann-Charlotte Garhammar
                </div>

                {isSwedish ? (
                  <>
                    <ul>
                      <li>
                        Guidning under Öland spirar våren 2025
                        <br />
                        <b className="pictures-time">10 / 5</b>kl 11 - 12
                        <br />
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>150 kr</strong> per person inklusive inträdet i
                        kyrkan
                      </li>
                      <li>
                        <span>Mötesplats:</span> Källa gamla kyrka
                      </li>
                      <li>
                        <span>Källa:</span>{" "}
                        <AppLink
                          external
                          to="https://maps.app.goo.gl/iaByMVD6mn9Ebm6S8"
                        >
                          Latitud 57,1114, longitud 16,9863
                        </AppLink>
                      </li>
                    </ul>
                    Ta gärna kontakt om du och din grupp önskar guidning i
                    kyrkan (minimum 6 st)
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
};

Church.propTypes = {
  media: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
};

export default Church;
